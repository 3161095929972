import React from "react";
import clsx from "clsx";

interface Props {
  innerContent: string;
  additionalClasses?: string;
}

export default function H1(props: Props) {
  return (
    <h1
      className={clsx(
        "text-3xl font-bold font-heading",
        props.additionalClasses
      )}
    >
      {props.innerContent}
    </h1>
  );
}
