import React from "react";
import clsx from "clsx";

interface ButtonProps {
  innerContent?: string;
  children?: any;
  additionalClasses?: string;
  color?: string;
  paddingClasses?: string;
  borderClasses?: string;
  link?: boolean;
  href?: string;
  fullWidth?: boolean;
  textSize?: string;
  onClick?: React.MouseEventHandler;
  isProcessingButton?: boolean;
  isProcessingDoneButton?: boolean;
  disabled?: boolean;
  type?: string;
}

export default function Button(props: ButtonProps) {
  // const defaultClasses = "py-2 px-4 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 transition ease-in duration-200 text-center  font-normal shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
  const defaultClasses = "    shadow-md";

  const buttonColor =
    props.color && props.color === "blue"
      ? "bg-blue hover:bg-blue-hover text-white"
      : props.color && props.color === "blue-light"
      ? "bg-blue-light text-black"
      : props.color
      ? "bg-" + props.color
      : "";

  const paddingClasses = props.paddingClasses
    ? props.paddingClasses
    : "py-2 px-4";

  const borderClasses = props.borderClasses
    ? props.borderClasses
    : "border border-blue rounded";

  const fullWidth = props.fullWidth ? "w-full" : "";

  const textSize = props.textSize ? "text-" + props.textSize : "text-base";

  const processingContent = (
    <>
      <svg
        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
      Processing...
    </>
  );

  const processingDoneContent = (
    <>
      <svg className="mr-2 h-5 w-5 text-white inline" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M9.55 19.91C9.10487 19.9068 8.67832 19.7312 8.36 19.42L2.16 13.21C2.08924 13.1411 2.03301 13.0586 1.99461 12.9676C1.95621 12.8766 1.93643 12.7788 1.93643 12.68C1.93643 12.5812 1.95621 12.4834 1.99461 12.3924C2.03301 12.3014 2.08924 12.2189 2.16 12.15L4.57 9.73C4.89374 9.41061 5.33023 9.23153 5.785 9.23153C6.23978 9.23153 6.67626 9.41061 7 9.73L9.78 12.52C9.81356 12.5524 9.85837 12.5705 9.905 12.5705C9.95163 12.5705 9.99645 12.5524 10.03 12.52L17.38 5.17C17.7002 4.86318 18.1265 4.69189 18.57 4.69189C19.0135 4.69189 19.4398 4.86318 19.76 5.17L21.21 6.62C21.5233 6.93482 21.6991 7.36088 21.6991 7.805C21.6991 8.24913 21.5233 8.67518 21.21 8.99L10.74 19.42C10.4217 19.7312 9.99513 19.9068 9.55 19.91ZM3.75 12.68L9.42 18.35C9.45356 18.3824 9.49837 18.4005 9.545 18.4005C9.59163 18.4005 9.63645 18.3824 9.67 18.35L20.1 7.93C20.1324 7.89645 20.1505 7.85163 20.1505 7.805C20.1505 7.75837 20.1324 7.71356 20.1 7.68L18.65 6.23C18.6148 6.19696 18.5683 6.17857 18.52 6.17857C18.4717 6.17857 18.4252 6.19696 18.39 6.23L11 13.58C10.6803 13.8839 10.2561 14.0533 9.815 14.0533C9.37392 14.0533 8.9497 13.8839 8.63 13.58L5.89 10.79C5.87519 10.7694 5.85567 10.7525 5.83306 10.7409C5.81046 10.7293 5.78541 10.7233 5.76 10.7233C5.73459 10.7233 5.70955 10.7293 5.68694 10.7409C5.66433 10.7525 5.64482 10.7694 5.63 10.79L3.75 12.68Z"
        />
      </svg>
      Saved!
    </>
  );

  const showInnerContent = props.isProcessingDoneButton
    ? processingDoneContent
    : props.isProcessingButton
    ? processingContent
    : props.children
    ? props.children
    : props.innerContent;

  const noClickHandler = () => {
    console.log("no click handler found.");
  };

  return props.link ? (
    <a
      href={props.href}
      className={clsx(
        defaultClasses,
        buttonColor,
        paddingClasses,
        borderClasses,
        fullWidth,
        textSize,
        props.additionalClasses,
        props.isProcessingButton ? "cursor-not-allowed" : ""
      )}
    >
      {showInnerContent}
    </a>
  ) : (
    <button
      type={props.type ? props.type : "button"}
      className={clsx(
        defaultClasses,
        buttonColor,
        paddingClasses,
        borderClasses,
        fullWidth,
        textSize,
        props.additionalClasses,
        props.isProcessingButton ? "cursor-not-allowed" : ""
      )}
      onClick={props.onClick ? props.onClick : noClickHandler}
      disabled={props.disabled ? true : false}
    >
      {showInnerContent}
    </button>
  );
}
