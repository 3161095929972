import React from "react";
import clsx from "clsx";

interface Props {
  innerContent: string;
  additionalClasses?: string;
  paddingClasses?: string;
}

export default function H2(props: Props) {
  const paddingClasses = props.paddingClasses ? props.paddingClasses : "pb-5-xx";
  return (
    <h2
      className={clsx(
        "text-2xl font-bold font-heading",
        paddingClasses,
        props.additionalClasses
      )}
    >
      {props.innerContent}
    </h2>
  );
}
