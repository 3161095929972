import React from "react";
import { PageProps } from "gatsby";

import Layout from "../components/layout";
import SearchBox from "../components/contentElements/searchBox";

import H1 from "../components/htmlElements/h1";
import H2 from "../components/htmlElements/h2";
import H4 from "../components/htmlElements/h4";
import Button from "../components/htmlElements/button";

const LeftColumnContent = () => {
  return (
    <>
      <H1 innerContent="Page title" additionalClasses="pb-5" />

      <p className="text-base">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
        consequat, nisi nec consectetur venenatis, sapien dolor condimentum
        ipsum, et efficitur dolor nulla at eros. Fusce sagittis rhoncus mauris
        in semper. In lobortis libero quis ligula tempor, in lacinia dolor
        ornare. Duis non est volutpat, pretium quam sit amet, ornare est. Mauris
        quis leo mauris. Duis sed tempus lectus. Pellentesque at lorem porta,
        posuere mi in, commodo augue. Maecenas iaculis finibus justo. Praesent
        luctus aliquet felis quis scelerisque. Vivamus ac odio pellentesque,
        iaculis massa sed, blandit massa. Nulla efficitur, tellus sit amet
        egestas vestibulum, ex mauris lobortis quam, sit amet viverra sem augue
        id ipsum. Maecenas sit amet massa vehicula ante interdum feugiat et sit
        amet risus. Cras commodo magna justo, quis luctus elit maximus porta.
      </p>

      <H2 innerContent="Sub title" additionalClasses="pb-5 mt-5" />

      <p className="text-base pb-5">
        Donec vitae pretium leo. Nulla lobortis viverra lectus, ac vulputate
        sapien molestie ac. Nam vitae pellentesque velit, in sollicitudin ex.
        Nulla sed interdum enim. Duis eget ipsum consequat, aliquet massa nec,
        tincidunt lorem. Donec malesuada congue risus. Curabitur posuere maximus
        magna, a sollicitudin risus lacinia quis. Curabitur vehicula metus ut
        leo pretium placerat.
      </p>

      <div className="pt-5 mt-0 border-t border-t-gray-2">
        <div className="bg-white rounded-lg shadow mx-auto sm:overflow-hidden sticky top-5 pt-3 pb-5 px-5">
          <H4 innerContent="Admin menu" additionalClasses="pb-3" />

          <p className="pb-3 text-xs">
            This page was last modified on `March 6, 20222 @ 12:05` by
            `Sebastiaan`
          </p>

          <div className="pt-3">
            <Button
              color="blue"
              additionalClasses="mr-5"
              href="/admin/editpage/123"
              link={true}
            >
              Edit this page
            </Button>

            <Button
              color="blue"
              additionalClasses="mr-5"
              href="/admin/editpage/123"
              link={true}
            >
              Clone this page
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default function IndexRoute(props: PageProps) {
  return (
    <>
      <Layout>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-7 p-5">
            <LeftColumnContent />
          </div>

          <div className="col-span-12 md:col-span-5 p-5">
            <SearchBox />

            <br />
          </div>
        </div>
      </Layout>
    </>
  );
}
