import clsx from "clsx";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import H2 from "../htmlElements/h2";

const searchCriterias = [
  {
    label: "Search for...",
    type: "input",
    name: "q",
  },
  // {
  // 	label: 'Type of Material',
  // 	type: 'dropdown',
  // 	name: 'material',
  // 	options: []
  // },
  // {
  // 	label: "General Research Area",
  // 	type: "input",
  // 	name: "research-area",
  // },
  // {
  // 	label: "Type of Publication(s)",
  // 	type: "input",
  // 	name: "publications",
  // },
];

function SearchInput({ option }) {
  const classes =
    "rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent";

  return (
    <input
      type="text"
      id={option.name}
      name={option.name}
      className="border border-gray-2 rounded px-2 py-2 font-sm   w-full  focus:outline-none focus:border-transparent"
      placeholder={option.label}
    />
  );
}

function SearchDropdown({ option }) {
  const selectOptions = option.options.map((option) => {
    return <option value={option.value}>{option.label}</option>;
  });

  return (
    <select
      id={option.name}
      name={option.name}
      className="rounded-lg border-transparent flex-1  border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
    >
      <option value="">{option.label}</option>
      {selectOptions}
    </select>
  );
}

interface SearchBoxProps {
  parent?: string;
}

function SearchBox(props: SearchBoxProps) {
  const { parent } = props;

  const searchOptions = searchCriterias.map((option) => {
    const field =
      option.type === "input" ? (
        <SearchInput option={option} />
      ) : option.type === "dropdown" ? (
        <SearchDropdown option={option} />
      ) : (
        ""
      );

    return (
      <div className="w-full" key={uuidv4()}>
        <div className=" relative ">{field}</div>
      </div>
    );
  });

  const defaultClasses =
    "bg-white rounded-lg shadow mx-auto sm:overflow-hidden  top-5";

  const classes = clsx(
    defaultClasses,
    parent && parent === "homepage" ? "" : "sticky"
  );

  return (
    <>
      {/* <div className="bg-white rounded-lg shadow sm:max-w-md sm:w-full sm:mx-auto sm:overflow-hidden"> */}

      <div className={classes}>
        <div className="p-5">
          <div className="w-full space-y-6">
            <H2
              innerContent="Search and download"
              additionalClasses="mt-0 pb-0"
            />

            <div className="text-sm m-0">
              Enter any text you wish into the search box below, such as an
              author, a type of instrument, a data collection method, a research
              area, a language, a linguistic feature, etc.
            </div>
          </div>

          <div className="mt-6">
            <div className="w-full space-y-6">
              <form action="/search/">
                {searchOptions}

                <button
                  className="bg-blue hover:bg-blue-hover text-white py-2 px-4 rounded    shadow-md mt-5"
                  type="submit"
                >
                  Search
                </button>
              </form>

              <p className="text-sm">
                Click search to <a href="/search">see everything in IRIS</a>,
                and then use filters to narrow down your search
              </p>
            </div>
          </div>
        </div>
        <div className="p-5 border-t border-gray-200 bg-gray-50">
          <p className="text-xs leading-5 text-gray-500">
            For terms you can search for, see the{" "}
            <a href="/search-help" className="">
              Search Help page
            </a>
            . Multiple terms separated by a space are automatically treated as
            term1 OR term2 OR ... searches.
          </p>
        </div>
      </div>
    </>
  );
}

export default SearchBox;
